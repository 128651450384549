.relatedproducts {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    height: 100%;
    
}
.relatedproducts h1 {
    color: #171717;
    text-shadow: #ff66c4 0px 0px 10px;
    font-size: 40px;
    font-weight: 600;
}
.relatedproducts hr {
    width: 200px;
    height: 6px;
    border-radius: 10px;
    background: #252525;
}
.relatedproducts-item {
    margin: 50px 0 150px 0;
    display: flex;
    gap: 30px;
    width:100%;
    justify-content: center;
}



@media screen and (max-width: 1550px){
    .relatedproducts-item {
        gap: 15px;
        width: 90%;
    }
    .relatedproducts-item .name{
        font-size: 22px;
    }
    .relatedproducts h1{
        font-size: 36px;
    }

}

@media screen and (max-width: 1280px){
    .relatedproducts{
        height: 100%;
    }
    .relatedproducts h1 {
        font-size: 40px;
    }
    .relatedproducts hr {
        width: 160px;
        height: 4px;
    }
    .relatedproducts-item {
        gap: 20px;
        margin-top: 30px;
    }
    .relatedproducts-item .name{
        font-size: 18px;
    }
}
@media screen and (max-width: 1080px){
    .relatedproducts{
        height: 100%;
    }
    .relatedproducts h1 {
        font-size: 30px;
    }
    .relatedproducts hr {
        width: 120px;
        height: 3px;
    }
    .relatedproducts-item {
        display: grid;
        grid-template-columns: 48% 48%;
        width: 100%;
        gap: 50px 0;
        justify-items: center;
    }
    .relatedproducts-item .item{
        width: 450px;
    }
}
@media  screen and (max-width: 990px){
    .relatedproducts{
        gap: 6px;
    }
    .relatedproducts h1 {
        font-size: 20px;
    }
    .relatedproducts hr {
        width: 100px;
    }
    .relatedproducts-item {
        gap: 10px;
        width: 100%;
    }
    .relatedproducts-item .item{
        width: 100%;
    }
    .relatedproducts-item .item-box{
        width: 100%;
    }
}
@media screen and (max-width: 500px){
    .relatedproducts-item {
        gap: 10px;
        width: 96%;
    }
    .relatedproducts{
        margin: 30px 0 0 0;
    }
    
}
