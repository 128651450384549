.productdisplay {
    display: flex;
    margin: 0px 3%;
    padding:40px 25px;
    background-color: #ff66c425;
    box-shadow: inset 0 0 20px #ff66c4;
    border: 1px solid grey;
}
.productdisplay-left {
    display: flex;
    gap: 17px;
}
.productdisplay-img-list {
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.productdisplay-img-list img {
    height: 163px;
    border-radius: 3px;
    box-shadow: 0 0 10px black;
}
.productdisplay-main-img {
    width: 586px;
    height: 700px;
    border-radius: 5px;
    box-shadow: 0 0 10px black;
}
.productdisplay-right {
    margin:40px;
    display: flex;
    flex-direction: column;
    margin-top: auto;
    padding:70px 10px;
}
.productdisplay-right h1 {
    color: #000000;
    font-size: 36px;
    font-weight: 700;
    
}
.productdisplay-right-stars {
    display: flex;
    align-items: center;
    margin-top: 13px;
    gap: 5px;
    color: #1c1c1c;
    font-size: 16px;
}
.productdisplay-right-prices {
    display: flex;
    margin: 30px 0px;
    font-size: 24px;
    font-weight: 700;
}
.productdisplay-right-price-old {
    color: #818181;
    text-decoration: line-through;
}
.productdisplay-right-price-new {
    color: green;
    font-size: 36px;
    padding-bottom: 100px;
    
}
.productdisplay-right-size h1 {
    color: black;
    font-size: 28px;
    font-weight: 600;
    padding: 10px;
}
.productdisplay-right-sizes {
    background-color: rgb(231, 231, 231);
    box-shadow: inset 0 0 10px grey;
    width: 50%;
    font-size: 20px;
    gap: 20px;
    margin: 0;
    border-radius: 5px;
    text-align: center;
}
.productdisplay-right-sizes div {
    padding: 18px 24px;
    background: #fbfbfb;
    border: 1px solid #ebebeb;
    border-radius: 3px;
    cursor: pointer;
}
.productdisplay-right button {
    display: inline-flex;
    justify-content: center;
    position: relative;
    overflow: hidden;
    align-items: center;
    padding: 20px 40px;
    margin:20px 10px;
    width: 200px;
    font-size: 16px;
    font-weight: 600;
    color: white;
    background-color: pink;
    border: none;
    outline: none;
    cursor: pointer;
    box-shadow: 0 0 10px black;
    border-radius: 25px;
}
.productdisplay-right span {
    position: relative;
    z-index: 1;
    color: black;
}
.productdisplay-right button::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    height: 1px;
    transform: translate(-50%,-50%);
    background: white;
    border-radius: 50%;
    transition: width 0.4s, height 0.4s,;
}
.productdisplay-right button:hover::before{
    width: 510px;
    height: 510px;
}
.productdisplay-right button:hover{
    box-shadow: 0 0 25px 10px #df979c;
}
.productdisplay-right-category {
    margin-top: 10px;
}
.productdisplay-right-category span {
    font-weight: 600;
}
.productdisplay-right-description{
    padding-bottom: 75px;
}
@media(max-width: 1280px){
    .productdisplay{
        margin: 0px 60px;
    }
    .productdisplay-left {
        gap: 10px;
    }
    .productdisplay-img-list {
        gap: 10px;
    }
    .productdisplay-img-list img {
        height: 120px;
    }
    .productdisplay-main-img {
        width: auto;
        height: 510px;
    }
    .productdisplay-right {
        margin: 0px 30px;
    }
    .productdisplay-right h1 {
        font-size: 22px;
    }
    .productdisplay-right-stars {
        gap: 3px;
        font-size: 13px;
    }
    .productdisplay-right-prices {
        margin: 10px 0px;
        font-size: 18px;
    }
    .productdisplay-right-description {
        font-size: 13px;
        padding-bottom: 50px;
    }
    .productdisplay-right-size h1 {
        margin-top: 20px;
        font-size: 20px;
    }
    .productdisplay-right-sizes div {
        padding: 14px 20px;
    }
    .productdisplay-right button {
        width: 150px;
        padding: 15px 0px;
        margin-bottom: 20px;
    }
    .productdisplay-right-category {
        margin-top: 5px;
        font-size: 14px;
    }
    .productdisplay-right-price-new {
        padding-bottom: 40px;
    }
}
@media(max-width: 1024px){
    .productdisplay{
        margin: 0px 30px;
    }
    .productdisplay-left {
        gap: 5px;
    }
    .productdisplay-img-list img {
        height: 80px;
    }
    .productdisplay-main-img {
        height: 350px;
    }
    .productdisplay-right h1 {
        font-size: 18px;
    }
    .productdisplay-right-stars img {
        width: 15px;
    }
    .productdisplay-right-description {
        font-size: 12px;
        padding-bottom: 10px
    }
    .productdisplay-right-sizes{
        margin: 10px 0px 30px 0;
        padding-bottom: 0;
    }
    .productdisplay-right-sizes div {
        padding: 10px 16px;
        font-size: 12px;
    }
    .productdisplay-right button {
        width: 120px;
        padding: 10px 0px;
        margin-bottom: 10px;
        font-size: 14px;
    }
    .productdisplay-right-category {
        font-size: 12px;
    }
    .productdisplay-right-price-new {
        padding-bottom: 30px;
        font-size: 26px;
    }
}
@media(max-width: 800px){
    .productdisplay{
        margin: 0px 10px;
    }
    .productdisplay-right{
        gap: 10px;
    }
    .productdisplay-left {
        gap: 0px;
        flex-direction: column-reverse;
        align-items: center;
    }
    .productdisplay-img-list {
        flex-direction: row;
        gap: 6px;
    }
    .productdisplay-img-list img {
        height: 70px;
    }
    .productdisplay-main-img {
        height: 300px;
    }
    .productdisplay-right h1 {
        font-size: 14px;
    }
    .productdisplay-right-stars img {
        width: 13px;
    }
    .productdisplay-right-description {
        font-size: 10px;
    }
    .productdisplay-right-size h1 {
        margin-top: 10px;
    }
    .productdisplay-right-sizes{
        gap: 5px;
    }
    .productdisplay-right-sizes div {
        padding: 4px 11px;
    }
    .productdisplay-right button {
        width: 100px;
        font-size: 12px;
        background-color: #ff66c4;
    }
    .productdisplay-right-category {
        font-size: 10px;
    }
    
}
@media(max-width: 500px){
    .productdisplay {
        flex-direction: column;
    }
    .productdisplay-left {
        gap: 10px;
        flex-direction:column-reverse;
        margin: auto;
    }
    .productdisplay-img-list {
        
        flex-direction: row;
        gap: 8px;
    }
    .productdisplay-img-list img {
        height: 75px;
    }
    .productdisplay-main-img {
        height: 375px;
        width: 100%;
    }
    .productdisplay-right {
        margin: 5px;
    }
    .productdisplay-right h1 {
        margin-top: 15px;
        font-size: 20px;
        font-weight: 500;
    }
    .productdisplay-right-stars img {
        width: 15px;
    }
    .productdisplay-right-sizes {
        gap: 10px;
        margin: 20px 0px;
    }
    .productdisplay-right-sizes div {
        padding: 10px 16px;
        font-size: 14px;
    }
    .productdisplay-right button {
        width: 130px;
        font-size: 15px;
        padding: 12px 0px;
    }
    .productdisplay-right-category {
        font-size: 16px;
    }
}
@media (max-width:380px) {
    .productdisplay-main-img {
        height: 300px;

    }
    .productdisplay-img-list img {
        height: 65px;
    }
}