.descriptionbox {
    margin: 120px 170px;
}
.descriptionbox-navigator {
    display: flex;
}
.descriptionbox-nav-box {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 600;
    width: 171px;
    height: 70px;
    border: 1px solid rgb(114, 114, 114);
    border-bottom: none;
    box-shadow: 0 0 3px black;
}
.descriptionbox-nav-box .fade {
    background: #fbfbfb;
    color: #555;
    border-bottom: none;
    box-shadow: 0 0 3px black;
}
.descriptionbox-description {
    display: flex;
    flex-direction: column;
    gap: 25px;
    border: 1px solid rgb(173, 173, 173);
    padding: 35px;
    padding-bottom: 60px;
    border-radius:0px 5px;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-size: 18px;
    box-shadow: 0 0 3px black;
}
#ig-link{
    color: #ff66c4;
    font-size: 22px;
}
@media(max-width: 1280px){
    .descriptionbox{
        margin: 60px;
    }
}
@media(max-width: 800px){
    .descriptionbox{
        margin: 60px 20px;
    }
    .descriptionbox-description {
        font-size: 14px;
        padding: 20px 20px 40px 20px;
    }
}