.confirmation-page{
    height: 80vh;
    width: 85%;
    margin: 0 auto;
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 8px;
    letter-spacing: 1px;
}
.confcontainer{
    background-color: #ff66c4c0;
    box-shadow: inset 0 0 10px black;
    
}
.confirmation-page h1 {
    text-align: center;
}
.confirmation-page h2 {
    font-size: 20px;
    font-weight: 500;
    text-align: center;
}
.social img{
padding:0px 15px;
margin-top: 15px;
}
.social{
    font-size: 20px;
}
.confirmation-page p {
    padding-top: 40px;
    font-size: 22px;
    text-align: center;

}
.returnbtn {
    padding: 20px 50px;
    margin-top: 40px;
    display: block;
    width: 200px;
    font-size: 26px;
    color: #ff66c4;
    background-color: black;
    border-radius: 25px;
    text-align: center;
}
@media (max-width:800px) {
    .confirmation-page{
        gap: 15px;
        height: 900px;
    }
    .confirmation-page h1 {
        font-size: 22px;
        
    }
    .confirmation-page h2 {
        font-size: 16px;
    }
    .returnbtn{
        font-size: 20px;
        padding: 15px 40px;
    }
}