.item{
    /*border: 1px solid rgb(0, 0, 0);*/
    border-radius:5px 5px 10px 10px;
    background-color: pink;
    box-shadow: 0 0 5px black;
}
.item:hover{
    box-shadow: 0 0 20px #ff66c4;
    background-color: #fd8cd2;
    scale: 1.05;
    border: 1px solid black;
}
.item-box{
    border-radius: 25px;
    justify-content: center;
    align-content: center;
    font-weight: 600;
}
.item:hover{
    transition: 0.4s ease-in-out;
}
.item img {
    width: 100%;
    height: 100%;
    border-radius:5px 5px 50px 50px ;

}

.item-text{
    margin: 20px 0 0 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.item p {
    width: 100%;
    color: black;
    z-index: 2;

}
.item-prices {
    display: flex;
    gap: 20px;
}
.item-price-new {
    color: rgba(63, 145, 26, 0.801);
    font-size: 32px;
    text-shadow: 0 3px 2px rgba(0, 0, 0, 0.637);
    font-weight:600;
}
.item-price-old {
    color: blueviolet;
    font-size: 18px;
    font-weight: 500;
    text-decoration: line-through;
}
.name{
    font-size: 26px;
    padding: 5px 0;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.size{
    font-weight: 500;
    font-size: 16px;
}

.item-btn{
    width: 60%;
    height: 40px;
    background-color: black;
    margin:10px auto;
    padding: 5px 0;
    text-decoration: none;
    display: block;
    border-radius: 25px;
    text-align: center;
    align-content: center;
    font-size: 22px;
    color: #ff66c4;
    text-shadow: 0 0 10px #ff66c4;
    box-shadow: 0 0 5px black;
}
.item-btn:hover{
    background-color: rgb(49, 49, 49);
    box-shadow: 0 0 15px #ff66c4;
    text-shadow: #ff66c4;
    color: pink;
}
@media(max-width: 1280px){
    .item-price-new {
        font-size: 18px;
    }
    .name{
        font-size: 18px;
    }
}
@media(max-width: 800px) {
    .item {
        width: 240px;
    }
}
@media(max-width: 550px){
    .item{
        width: 100%;
        box-shadow: none;
        border: 1px solid pink;
    }

    .item-btn{
        width: 80%;
        height: 25px;
        font-size: 14px;
    }
    .item-text{
        margin-top: 5px;

    }
    .name {
        font-size: 14px;
        margin-right: 10px;
    }
    .size{
        font-size: 14px;
    }
}
@media(max-width: 407px){
    .item {
        width: 110px;
        font-size: 13px;
    }
    .item img {
        height: 170px;
    }
}



/*@keyframes glowBorder {
    0%   { left:0px; top:0px;box-shadow: 0 0 10px black}
    25%  { left:0px; top:0px; box-shadow:inset 0 0 1px white;}
    50%  { left:0px; top:0px;box-shadow: 0 0 10px black;}
    75%  { left:0px; top:0px; box-shadow:inset 0 0 1px white;}
    100% { left:0px; top:0px;box-shadow: 0 0 10px black;}
  }*/