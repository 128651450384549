.loginsignup {
    width: 100%;
    height: 80vh;
    padding-top: 100px;
}
.loginsignup-container {
    width: 580px;
    height: 600px;
    background-color: rgba(0, 0, 0, 0.884);
    color: #ff66c4;
    margin: auto;
    padding: 40px 60px;
    border-radius: 15px;
    box-shadow: 0 0 10px black;
}
.loginsignup-container h1 {
    margin: 20px 0px;
}
.loginsignup-fields {
    display: flex;
    flex-direction: column;
    gap: 29px;
    margin-top: 30px;
}
.loginsignup-fields input {
    height: 72px;
    width: 100%;
    padding-left: 2px;
    border: 1px solid #c9c9c9;
    outline: none;
    color: #5c5c5c;
    background-color: rgb(255, 212, 219);
    font-size: 18px;
    border-radius: 5px;
}
.loginsignup-container button {
    width: 580px;
    height: 72px;
    color: black;
    background: #ff66c4;
    margin-top: 30px;
    border: none;
    font-size: 24px;
    font-weight: 500;
    cursor: pointer;
}
.loginsignup-login {
    margin-top: 20px;
    color: pink;
    font-size: 18px;
    font-weight: 500;
}
.loginsignup-login span {
    color: #ff66c4;
    font-weight: 600;
}
.loginsignup-agree {
    display: flex;
    align-items: center;
    margin-top: 25px;
    gap: 20px;
    color: pink;
    font-size: 18px;
    font-weight: 500;
}
@media(max-width: 1280px) {
    .loginsignup {
        padding-top: 50px;
    }
    .loginsignup-container {
        width: auto;
        max-width: 500px;
        max-height: 500px;
    }
    .loginsignup-container h1 {
        margin: 10px 0px;
    }
    .loginsignup-fields {
        gap: 20px;
        margin-top: 20px;
    }
    .loginsignup-fields input {
        height: 65px;
        width: 93%;
    }
    .loginsignup-container button {
        width: 100%;
    }
    .loginsignup-login{
        font-size: 16px;
    }
    .loginsignup-agree {
        font-size: 14px;
        gap: 10px;
    }
}
@media(max-width: 800px){
    .loginsignup-container {
        padding: 20px 30px;
    }
    .loginsignup-container h1 {
        font-size: 24px;
    }
    .loginsignup-fields {
        margin-top: 20px;
    }
    .loginsignup-fields input {
        height: 50px;
    }
    .loginsignup-container button {
        height: 50px;
        font-size: 16px;
    }
}