.new-collections {
    display: flex;
    flex-direction: column;
    box-shadow: inset 0 0 10px black;
    background-color: pink;
    gap: 20px;
}
.new-collections h1 {
    color: #171717;
    font-size: 32px;
    font-weight: 600;
    text-shadow: #ff66c4 0px 0px 10px;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    padding: 50px 0 0 9%;
}
.collections {
    display: grid;
    gap:  50px 30px;
    grid-template-columns: 30% 30% 30%;
    padding: 25px 0;
    margin: 0 auto;
    align-content: center;
    justify-content: center;
    width: 90%;
}

@media screen and (max-width: 1280px){
    .new-collections h1 {
        font-size: 40px;
    }
    .new-collections hr {
        width: 160px;
        height: 4px;
    }
    .collections {
        margin-top: 30px;
        width: 96%;
        grid-template-columns: 32% 32% 32% ;
    }
}
@media screen and (max-width: 1105px){
    .new-collections {
        padding-top: 30px;
    }
    .new-collections h1 {
        font-size: 30px;
    }
    .new-collections hr {
        width: 120px;
        height: 3px;
    }
    .collections {
        display: grid;
        grid-template-columns: .9fr .9fr .9fr;
        padding: 40px 0 ;
    }
}
@media  screen and (max-width: 800px){
    .new-collections{
        gap: 6px;
        background-color: rgba(255, 255, 255, 0.623);
    }
    .new-collections h1 {
        font-size: 26px;
    }
    .new-collections hr {
        width: 100px;
    }
    .collections {
        grid-template-columns: 49% 49%;
        width: 96%;
        column-gap: 6px;
    }
    .collections .item {
        width: 100%;
    }
    .collections .item:hover {
        scale: 1;
    }
}
@media screen and (max-width: 560px){
    .collections {
        row-gap: 35px;
        padding:0 0 40px 0;
    }
    .new-collections h1 {
        padding-top: 15px;
    }
    .new-collections{
        padding-top: 0;
    }
    .collections .name{
        font-size: 18px;
    }
}
@media screen and (max-width: 500px){

}