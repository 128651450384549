.popular {
    display: flex;
    flex-direction: column;
    height: 640px;
    background-color: rgba(0, 0, 0, 0.99);
    
}
.popular h1 {
    color: black;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-size: 32px;
    font-weight: 600;
    align-content: center;
    width: 300px;
    padding: 35px 0 0 9%;
    
}
#text{
    color: pink;
    text-shadow: 0 0 5px #ff66c4;
}
.popular-item {
    margin: 50px auto;
    display: grid;
    border-radius: 10px;
    width: 90%;
    grid-template-columns: 20% 20% 20% 20% ;
    justify-content: center;
    gap: 40px;
}
.popular-item .item{
    background-color: rgb(255, 192, 203);
    border-radius:5px 5px 10px 10px;
    box-shadow: 0 0 10px pink;

}
.popular-item .name {
    font-size: 20px;
}
.popular-item .item-price-new{
    font-size: 26px;
}
.popular-item .item-btn {
    width: 80%;
    height: 30px;
    font-size: 18px;
    background-color: #ff66c4;
    color: #000;
}
.popular-item img{
    width: 100%;
    height: 350px;
    border-radius: 5px 5px 50px 50px;
    margin-right: auto;
}

@media screen and (max-width: 1768px){
    .popular-item{
        grid-template-columns: 23% 23% 23% 23%;
    }
}
@media screen and (max-width:1500px){
    .popular-item{
        width: 96%;
        gap: 20px;
    }
}    

@media screen and (max-width: 1318px){
    .popular{
        height: 550px;
    }
    .popular hr {
        width: 160px;
        height: 4px;
    }
    .popular-item {
        gap: 15px;
        margin-top: 30px;
        grid-template-columns: 24% 24% 24% 24%;
    }
    .popular-item .item img{
    height: 275px;
    }
    .popular-item .item-price-new{
        font-size: 22px;
    }
    .popular-item .name {
        font-size: 18px;
    }
}
@media screen and (max-width: 1024px){
    .popular{
        height: 100%;
    }
    .popular h1 {
        font-size: 30px;
    }
    .popular hr {
        width: 120px;
        height: 3px;
    }
    .popular-item {
        display: grid;
        grid-template-columns: 48% 48%;
        width: 100%;
        justify-items: center;
        gap: 40px 0;
    }
    .popular-item .item img{
        height: 350px;
    }
    .popular-item .item {
        width: 90%;
    }
}

@media  screen and (max-width: 800px){
    .popular{
        gap: 6px;
    }
    .popular h1 {
        font-size: 26px;
    }
    .popular hr {
        width: 100px;
    }
    .popular-item .item-text {
        margin: 10px 0 0 10px;
    }
}
@media screen and (max-width: 600px){
    .popular-item{
        grid-template-columns: 49% 49%;
    }
    .popular-item .item img{
        height: 275px;
    }
    .popular-item .name {
        font-size: 16px;
        font-weight: 700;
    }
}
@media screen and (max-width: 500px){
    .popular{
        gap: 0px;
    }
    .popular h1 {
        font-size: 26px;
        padding-top: 30px;
    }
    .popular-item{
        margin-top: 20px;
        grid-template-columns: 50% 50%;
    }
    .popular-item .size{
        font-size: 12px;
    }
    .popular-item .name{
        font-size: 14px;
    }
    .popular-item .item-price-new{
        font-size: 20px;
    }
    .popular-item .item img{
        height: 230px;
    }
    .popular-item .item-btn{
        height: 25px;
        font-size: 14px;
    }
}
@media(width > 2150px){
    .popular-item{
        width: 80%;
    }
}