
.navbar {
    display: grid;
    grid-template-areas:
    "logo logo logo"
    "links links cart";
    justify-content: space-around;
    padding: 5px 0 5px 0px;
    align-items: center;
    width: 100%;
    font-family: Kaushan Script;
}
.nav-logo {
    display: flex;
    align-items: center;
    gap: 5px;
}
.logo-text{
    margin-top: 35px;
    font-size: 28px;
    color: #ff66c4;
    text-shadow: 0 2px 2px black;
    letter-spacing: 1px;
}
#logo {
    width: 90px;
    height: 100px;
}
#cart {
    width: 25px;
    height: 33px;
    margin:0px 0 50px 55px;
}
.drop-cart{
    flex-direction: column;
}
.nav-logo p{
    color: #050505;
    font-size: 25px;
    font-weight: 600;
}
.nav-links {
    display: flex;
    align-items: center;
    list-style: none;
    gap: 50px;
    color:#626262;
    font-size: 16px;
    margin-top: 50px;
    padding: 0;
}
.nav-links li {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3px;
    cursor: pointer;
    color: rgb(0, 0, 0);
    font-weight: 500;

}
.nav-links hr {
    border: none;
    width: 90%;
    height: 3px;
    border-radius: 10px;
    background: #ff66c4;
}
.nav-login-cart {
    display: flex;
    align-items: center;
    margin-left: auto;
    flex-direction: column-reverse;
    margin-right: 30px;
}

.nav-login-cart button{
    width: 115px;
    height: 35px;
    box-shadow: 0 0 5px black;
    outline: none;
    border: none;
    border-radius: 15px;
    color: black;
    font-size: 16px;
    font-weight: 600;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background: #ff66c4;
    cursor: pointer;
    margin-top: 25px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
}
.nav-login-cart .span-login {
    position: relative;
    z-index: 1;
}
.nav-login-cart button::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    height: 1px;
    transform: translate(-50%,-50%);
    background: pink;
    border-radius: 50%;
    transition: width 0.5s, height 0.5s,;
    z-index: 0;
}
.nav-login-cart button:hover::before{
    width: 810px;
    height: 810px;
}
.nav-login-cart button:hover {
    box-shadow: 0 0 10px 5px #ff66c4;
    color: white;
}
.nav-cart-count {
    width: 22px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -100px;
    margin-bottom: 20px;
    margin-left: 80px;
    border-radius: 11px;
    font-size: 14px;
    background: #ff66c4;
    color: black;
}
.nav-dropdown {
    display: none;
    margin-left: 35px;
}
@media(max-width: 1292px) {
    .nav-logo img {
        width: 40px;
    }
    .nav-logo p {
        font-size: 23px;
    }
    .nav-links {
        gap: 30px;
    }
    .nav-login-cart {
        gap: 0px;
    }
    .nav-login-cart button {
        font-size: 16px;
    }
    .nav-cart-count {
        font-size: 12px;
    }
}
@media(max-width: 1124px) {
    .navbar {
        padding: 12px 0px;
    }
    .nav-logo img {
        width: 30px;
    }
    .nav-logo p {
        font-size: 20px;
    }
    .nav-links {
        gap: 25px;
        font-size: 18px;
        font-weight: 600;
    }
    .nav-login-cart button {
        height: 35px;
        font-size: 14px;
    }
    .nav-login-cart img {
        width: 30px;
    }
    .nav-cart-count {
        width: 18px;
        height: 18px;
    }
}
@media(max-width: 970px) {
    .navbar {
        padding: 10px 0px;
    }
    .nav-menu-visible {
        display: flex;
    }
    .nav-dropdown.open {
        transform: rotate(90deg);
    }
    .nav-links{
        font-size: 16px;
    }
}
@media(max-width: 770px){
    /*.nav-dropdown {
        display: block;
        width: 30px;
        transition: 0.5s;
    }*/
    
    .logo-text{
        display: flex;
        color: #ff66c4;
        font-size: 40px;
    }
    .nav-links {
        margin-top: 70px;
        height: 33px;
        width: 100%;
        position: absolute;
        background-color: #ff66c4f6;
        justify-content: center;
        box-shadow: 0 0 5px black;
        gap: 50px;
        font-size: 16px;
        border-radius: 0 0 10px 10px;
        padding-left: 0;
    }
    .nav-links hr {
        background: black;
    }
    .navbar {
        padding: 8px 0px;
        gap: 0;
    }
    .nav-logo {
        transform: scale(0.8);
        align-items: end;
    }
    
    .nav-links {
        height: 60px;
        top: 50px;
    }
    .nav-login-cart {
        transform: scale(0.8);
    }
}
@media(max-width: 500px) {
    .navbar{
        padding: 0;
    }
    .nav-logo {
        margin-left: -15px;
    }
    .logo-text{
        padding-bottom: 10px;
    }
    .nav-links{
        font-size: 14px;
        margin-top: 50px;
        gap: 27px;
    }

    .nav-login-cart button {
        width: 100px;
        height: 30px;
        margin-top: 25px;
    }
    .nav-login-cart{
        margin-right: 0px;
    }
}
@media(max-width: 416px) {
    .nav-links{
        font-size: 13px;
        margin-top: 50px;
        gap: 25px;
    }
    #logo {
        width: 80px;
        height: 100px;
    }
    
    .logo-text {
        font-size: 30px;
        margin-right: -20px;
    }
    .nav-login-cart button {
        width: 70px;
        height: 25px;
        margin-top: 25px;
        font-size: 12px;
    }
}



@keyframes glowBorder {
    0%   {background-color:white; left:0px; top:0px;}
    25%  {background-color:#df979c; left:300px; top:0px; box-shadow: 0 0 0 0 transparent;}
    50%  {background-color:white; left:300px; top:300px;box-shadow: 0 0 50px 10px white;}
    75%  {background-color:#df979c; left:0px; top:200px;box-shadow: 0 0 0 0 transparent;}
    100% {background-color:white; left:0px; top:0px;box-shadow: 0 0 50px 10px white;}
  }