.footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background-color: rgb(0, 0, 0);
    width: 100%;
}
.footer-logo {
    display: flex;
    align-items: center;
    gap: 20px;
}
.f-logo {
    height: 100px;
    height: 100px;
}
.footer-logo p {
    color: #ff66c4;
    font-size: 40px;
    font-weight: 700;
    font-family: Kaushan Script;
}
.footer-links {
    display: flex;
    list-style: none;
    gap: 50px;
    font-size: 18px;
}
.footer-links li {
    cursor: pointer;
}
.footer-links a {
    color:pink;
}
.footer-social-icon {
    display: flex;
    gap: 20px;
}
.footer-icons-container {
    padding: 7px 10px;
    border-radius: 25px;
    background:#ff66c4;
    border: 1px solid rgb(156, 156, 156);

}
.footer-icons-container img {
    width: 31px;
    height: 31px;
}
.footer-copyright {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: 100%;
    margin-bottom: 15px;
    color: #999999;
    font-size: 16px;
}
.footer-copyright hr {
    width: 80%;
    border: none;
    border-radius: 10px;
    height: 1px;
    background: pink;
}
@media(max-width: 800px){
    .footer {
        gap: 20px;
    }
    .footer-logo {
        gap: 10px;
        align-items: end;
    }
    .footer-logo p {
        font-size: 25px;
    }
    .footer-links{
        font-size: 14px;
        gap: 10px;
    }
    .footer-social-icon img {
        width: 22px;
        height: 22px;
    }
    .footer-copyright {
        font-size: 13px;
    }
    .f-logo {
        width: 85px;
        height: 100px;
    }
}