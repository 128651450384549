.shopcategory-indexSort {
    display: flex ;
    margin: 0px 170px;
    justify-content: space-between;
    align-items: center;
}
.shop-category {
    height: 100%;
    box-shadow: inset 0 0 10px black;
}
.shopcategory-sort {
    padding: 10px 20px;
    border-radius: 40px;
    border: 1px solid #888;
    margin-top: 8px;
}
.shopcategory-indexSort p span {
    font-weight: 600px;
}
.shopcategory-products {
    margin: 20px auto 0px auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 40px 30px;
    width: 85%;
    align-items: center;
    height: 100%;
    padding: 50px 0;
    justify-content: center;
}
.shopcategory-loadmore {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 150px auto;
    width: 233px;
    height: 69px;
    border-radius: 75px;
    background: #ededed;
    color: #787878;
    font-size: 18px;
    font-weight: 500;
}
@media (max-width:1950px) {
    .shopcategory-products{
        grid-template-columns: 32% 32% 32% ;
    }
}
@media (max-width:1660px) {
    .shopcategory-products{
        width: 85%;
    }
    .shopcategory-products .name{
        font-size: 22px;
    }
    .shopcategory-products .item-btn{
        font-size: 22px;
        width: 80%;
        height: 40px;
    }
}
@media(max-width: 1280px) {
    .shopcategory-indexSort {
        width: 90%;
        margin: auto;
    }
    .shopcategory-sort {
        padding: 5px 10px;
        font-size: 12px;
    }
    .shopcategory-indexSort p {
        font-size: 12px;
    }
    .shopcategory-products {
        width: 90%;
        margin: 0px auto;
        gap:40px 10px;
    }
    .shopcategory-products .item {
        margin: auto;
    }
    .shopcategory-loadmore {
        margin: 100px auto;
        width: 200px;
        height: 60px;
        font-size: 16px;
    }
    .shopcategory-products .name{
        font-size: 18px;
    }
    .shopcategory-products .item-btn{
        font-size: 18px;
        width: 75%;
        height: 30px;
    }
}
@media (max-width:1024px) {
    .shopcategory-products{
        grid-template-columns: 33% 33% 33%;
    }
    .shopcategory-products .name{
        font-size: 18px;
    }
}
@media(max-width: 873px) {
    .shopcategory-products {
        width: 100%;
        gap:40px 10px;
        grid-template-columns: 45% 45%;
    }
    .shopcategory-loadmore {
        margin: 60px;
        width: 150px;
        height: 40px;
        font-size: 13px;
    }
    .shopcategory-products .item{
        width: 100%;
    }
    .shopcategory-products .item-price-new{
        font-size: 22px;
    }
}
@media(max-width: 770px) {
    .shopcategory-products{
        padding-top: 75px;
    }
}
@media(max-width: 615px) {
    .shopcategory-products .name{
        font-size: 14px;
        font-weight: 700;
    }
    .shopcategory-products .size{
        font-size: 12px;
    }
    .shopcategory-products .item-btn {
        font-size: 14px;
        height: 25px;
    }
    .shopcategory-products .item-price-new{
        font-size: 18px;
    }
}
@media(max-width: 480px) {
    .shopcategory-products .name{
        font-size: 12px;
        font-weight: 700;
    }
    .shopcategory-products .size{
        font-size: 11px;
    }
    .shopcategory-products .item-btn {
        font-size: 12px;
        height: 21px;
    }
}

